
let itemsName = null;
let itemsRef = null;

export default {
  props: {
    value: Boolean,
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
    orgId: String,
    orgName: String,
  },
  data() {
    return {
      // 要個別設定
      itemsName: null,
      itemsRef: null,
      type: 'customer', // management, center, reception, customer

      dialogModel: false,
      dialogTitle: '',
      currentItem: null,
      currentMode: 'none',
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      nameKanaRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      accessType: 'normal', // アクセス権限モード normal or admin
      remarks: '',
      isActionBtnDisabled: false,  // ボタン無効/有効フラグ
      isDeletedItem: false
    }
  },
  methods: {
    closeDialog() {
      // console.log('closeDialog');
      this.dialogModel = false;
      this.$emit('input', false);
      this.$emit('closed', this.currentItem);
    },
    onAppDialogClosed() {
      this.appDialogMode = 'none';
      this.$refs.email.focus();
    },
    newItemForm() {
      this.mode = 'new';
      this.dialogTitle = '作成';
      this.currentItem = {
        email: '',
        name: '',
        name_kana: '',
        phone_number: '',
        remarks: '',
        type: this.type,
        role: this.role,
      };
      this.dialogModel = true;
      setTimeout(() => this.$refs.email.focus(), 300);
    },
    viewItemForm() {
      this.currentMode = 'view';
      this.dialogTitle = '詳細';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      this.dialogModel = true;
      this.writeLog(['view'], `詳細画面を開きました。`, this.item); // 操作記録
    },
    editItemForm() {
      this.currentMode = 'edit';
      this.dialogTitle = '更新';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      this.dialogModel = true;
      setTimeout(() => this.$refs.name.focus(), 300);
      this.writeLog(['edit'], `更新画面を開きました。`, this.item); // 操作記録
    },
    async newItem(item) {
      this.currentMode = 'new';
      item.email = item.email.trim();
      item.deleted = null;
      item.org_id = this.orgId;
      item.role = this.accessType == 'admin' ? this.type + '-admin': this.type;

      // 入力エラーがある場合は終了
      if (!this.$refs[`form`].validate()) return;

      // メールアドレスを小文字に変換する
      item.email = item.email.toLowerCase();

      // 実施確認
      const confirm = await this.$root.$confirm(`確認`, `メールアドレス【 ${item.email} 】のスタッフを登録します。よろしいですか？`);
      if (!confirm) return;

      // 組織の削除状態を確認
      const itemType = item.type == 'customer' ? 'customer_orgs' : 'staff_orgs';
      const orgDoc = await this.$db.collection(itemType).doc(item.org_id).get();
      if (!orgDoc.exists) {
        await this.$root.$alert('エラー', `所属組織の情報が取得できません。`);
        return;
      }
      const orgData = orgDoc.data();
      if (orgData.deleted) {
        await this.$root.$alert('エラー', `所属する組織が削除されているため追加できません。`);
        return;
      }

      // 検索
      let result = await this.$root.$progressive(this.$functions.isDuplicatedEmail({ email: item.email }));
      if (result.data.result == 'Error') {
        throw new Error(JSON.stringify(result.data.errors));
      } else if (result.data.result != 'Not registerd') {
        await this.$root.$alert('エラー', '入力されたEmailアドレスは、既に登録されています。');
        return;
      }

      // itemの情報をFirebase上に追加登録する
      item.created = this.$firebase.firestore.FieldValue.serverTimestamp();
      item.modified = item.created;

      try {
        // 登録
        const promise = this.$db.runTransaction(async (t) => {
          // ロール
          const role = item.role.endsWith('-admin') ? 'admin' : 'general';
          const org_id = item.org_id;
          const roleDocRef = this.$db.doc('roles/' + item.type + '/staffs/' + item.email);
          await t.set(roleDocRef, { role, org_id })
        });
        await this.$root.$progressive(promise);
        const result = await this.$root.$progressive(itemsRef.add(item));

        // メールの送信
        await this.$root.$progressive(this.$functions.sendMailStaffRegistration({
          email: item.email,
          name: item.name,
          org_name: orgData.name,
        }));

        // 通知の作成
        let data =  {
          type: this.$notifications.STAFF_REGISTRATION,
          org_id: item.org_id,
          user_id: result.id,
          title: '初めてご利用されるお客様へ',
          message: `EG Holterシステムをご利用いただき誠にありがとうございます。\nメニューの「ヘルプ」内に使い方などを記載したマニュアルを掲載しておりますのでご確認ください。\nこのお知らせの「確認」ボタンをクリックするとヘルプ画面を表示します。`,
          link: {name: 'help', params:{}}
        };
        await this.$notifications.create(data);

        // 操作履歴
        this.writeLog(['new'], `新規登録しました。`, item);

        // ポップアップ画面表示
        let msg = [
          `スタッフの登録と、登録されたスタッフへのメール送信が完了しました。`,
        ];
        await this.$root.$alert_multi_line('確認', msg);

        this.dialogModel = false;
        this.$emit('input', false);
        this.$emit('closed', item);
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', '新規ユーザー情報の登録に失敗しました。');
      }
    },
    async saveItem(item) {
      // console.log('saveItem', item);

      // 入力エラーがある場合は終了
      if (!this.$refs[`form`].validate()) return;

      item.role = this.accessType == 'admin' ? this.type + '-admin': this.type;

      let updateData = item;

      // 生成日時・ログインメール送信日時・利用規約・削除日時は更新しない
      delete updateData.created;
      delete updateData.send_login_mail_datetime;
      delete updateData.terms_of_services;
      delete updateData.deleted;

      Object.assign(updateData, {
          modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
      });

      try {
        const doc = await this.$root.$progressive(itemsRef.doc(item.id).get());
        let beforeData = doc.exists ? doc.data() : null;
        
        // 更新
        const promise = this.$db.runTransaction(async (t) => {
          // スタッフ
          const staffDocRef = itemsRef.doc(item.id);
          const staffDoc = await t.get(staffDocRef);
          if (!staffDoc.exists) return Promise.reject('スタッフドキュメントが存在しません。');
          const staffData = staffDoc.data();
          // ロール
          if (
            this.$store.state.user.role.endsWith('-admin')
            || (
              this.$store.state.user.role == 'management' 
              && staffData.type != 'management'
            )
          ) {
            // ログインユーザーが管理ユーザー、または、運営主体一般ユーザーかつ対象スタッフが運営主体以外の場合のみ
            const role = item.role.endsWith('-admin') ? 'admin' : 'general'
            const org_id = item.org_id;
            const roleDocRef = this.$db.doc('roles/' + item.type + '/staffs/' + item.email);
            t = await t.set(roleDocRef, { role, org_id }, { merge: true })
          }
          // 更新
          t = await t.update(staffDocRef, updateData);
        });
        await this.$root.$progressive(promise);
        
        this.writeLog(['update'], `更新しました。`, item, { before: beforeData, after: updateData });  // 操作記録

        this.dialogModel = false;
        this.$emit('input', false);
        this.$emit('closed', item);
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', 'ユーザー情報の保存に失敗しました。');
      }
    },
    async deleteItem(item) {
      // 削除確認のダイアログを表示
      const result = await
        this.$root.$confirm(`確認`, `ユーザーアカウント ${item.email}を本当に削除しますか？`);

      if (result) {
        // 削除処理
        try {
          const promise = this.$db.runTransaction(async (t) => {
            // スタッフ（ソフトデリーション）
            const staffDocRef = itemsRef.doc(item.id);
            const staffDoc = await t.get(staffDocRef);
            if (!staffDoc.exists) return Promise.reject('スタッフドキュメントが存在しません。');
            // ロール
            const roleDocRef = this.$db.doc('roles/' + item.type + '/staffs/' + item.email);
            // 更新
            t = await t.update(staffDocRef, { deleted: this.$firebase.firestore.FieldValue.serverTimestamp() });
            t = await t.delete(roleDocRef);
          });
          await this.$root.$progressive(promise);

          this.writeLog(['delete'], `削除しました。`, item);  // 操作記録
        } catch (e) {
          console.error(e);
          await this.$root.$alert('エラー', `ユーザーアカウント ${item.email} の削除に失敗しました。`);
        }
      }
      this.$emit('input', false);
      this.$emit('closed', item);
    },
    async revertItem(item) {
      try {
        // 所属組織の削除状態を確認
        const itemType = item.type == 'customer' ? 'customer_orgs' : 'staff_orgs';
        const orgDoc = await this.$db.collection(itemType).doc(item.org_id).get();
        if (!orgDoc.exists)  throw new Error(`所属組織の情報が取得できません。`);
        const orgData = orgDoc.data();
        if (orgData.deleted) {
          await this.$root.$alert('エラー', `所属する組織が削除されているため復元できません。`);
          return;
        }

        // 検索
        let result = await this.$root.$progressive(this.$functions.isDuplicatedEmail({ email: item.email }));
        if (result.data.result == 'Error') {
          throw new Error(JSON.stringify(result.data.errors));
        } else if (result.data.result != 'Not registerd') {
          await this.$root.$alert('エラー', '復元しようとしているスタッフのEmailアドレスは、既に登録されています。');
          return;
        }

        // 復旧確認のダイアログを表示
        result = await this.$root.$confirm(`確認`, `ユーザーアカウント ${item.email}を元に戻しますか？`);
        if (!result) return;
 
        // 復元処理
        const promise = this.$db.runTransaction(async (t) => {
          // スタッフ
          const staffDocRef = itemsRef.doc(item.id);
          const staffDoc = await t.get(staffDocRef);
          if (!staffDoc.exists) return Promise.reject('スタッフドキュメントが存在しません。');
          // ロール
          const role = item.role.endsWith('-admin') ? 'admin' : 'general'
          const roleDocRef = this.$db.doc('roles/' + item.type + '/staffs/' + item.email);
          // 更新
          t = await t.update(staffDocRef, { deleted: null });
          t = await t.set(roleDocRef, { role, org_id: item.org_id }, { merge: true })
        });
        await this.$root.$progressive(promise);

        // 操作記録
        this.writeLog(['revert'], `復元しました。`, item);
      } catch (e) {
        console.warn(e);
        await this.$root.$alert('エラー', e.message);
      } finally {
        this.$emit('input', false);
        this.$emit('closed', item);
      }
    },
    // 各種アクションボタンクリック
    async onClickActionBtn(functionName, item) {
      // 処理中の場合は終了
      if (this.isActionBtnDisabled) return;
      this.isActionBtnDisabled = true;
      try {
        // 指定された関数の実行
        await this[functionName](item);
      } finally {
        // 処理中の解除
        this.isActionBtnDisabled = false;
      }
    },
    writeLog(tags, message, item, option = null) {
      tags = [this.itemsName].concat(tags);
      message = `${this.typeName}：${this.orgName}：${item.name} (${item.email})：${message}`
      this.$functions.log({ tags, message, item: option });
    },
  },
  mounted() {
    itemsName = this.itemsName;
    itemsRef = this.$db.collection(`${itemsName}`);
    this.currentItem = JSON.parse(JSON.stringify(this.item));
    console.log('StaffDialog mounted', this.orgId, this.props);

    this.currentMode = this.mode;
    this.dialogModel = this.value;

    // itemがnullとなるケースがあるため、item自体の存在確認も行う
    if (this.item && this.item.deleted) {
      this.isDeletedItem = true;
    }

    if (this.currentItem !== null && this.currentItem.role) {
      this.accessType = this.currentItem.role.endsWith('-admin') ? 'admin': 'normal';
    }
    if (this.mode == 'delete') {
      this.deleteItem(this.currentItem);
      return;
    } else if (this.mode == 'revert') {
      this.revertItem(this.currentItem);
      return;
    } else if (this.mode == 'new') {
      this.newItemForm();
    } else if (this.mode == 'view') {
      this.viewItemForm();
    } else if (this.mode == 'edit') {
      this.editItemForm();
    }
  },
  watch: {
    value(value) {
      // console.log('watch UserDialog v-model', value);
      this.dialogModel = value;
    },
    item(newItem) {
      // console.log('watch item at UserDialog, newItem');
      // // currentItemにディープコピーする。
      // this.currentItem = JSON.parse(JSON.stringify(newItem));
      console.log(newItem);
    },
    mode(newMode) {
      console.log('newMode', newMode);
    }
  }
}
